body {
  margin: 0;
  font-family: Georgia, serif; 
  font-weight: 100;
  background-color: #F8F4F0;
  width: 100%
}

.container {
  background-color: #F8F4F0;
  width: 375px;
  margin: 0 auto;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 24px 32px;
  box-sizing: border-box;
}

.inner-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 20px;
  height: 20px;
}

.input-field {
  display: flex;
  align-items: center;
  height: 20px;
}

.input-field p {
  width: 15px;
  margin-right: 3px;
  font-size: 18px;
  line-height: 20px;
  color: #BFBAB0;
  height: 20px;
}

.icon-flower {
  width: 20px;
  height: 20px;
  margin-left: 4px;
}

.input-result {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  color: #BFBAB0;
  font-family: Georgia, serif;
  font-size: 14px;
  font-weight: 100;
  line-height: 20px;
  margin-left: 12px;
  width: 60px;
  
}

.input-result p {
  margin: 0;
  margin-right: 4px;
  width: 100%;
  text-align: right;
}

.icon-flower {
  width: 20px;
  height: 20px;
  margin: 0;
  display: block;
}
  
.icon-flower.green {
  background-image: url('/src/images/green-flower.svg');
}

.icon-flower.yellow {
  background-image: url('/src/images/yellow-flower.svg');
}

.icon-flower.orange {
  background-image: url('/src/images/orange-flower.svg');
}

.icon-flower.grey {
  background-image: url('/src/images/grey-flower.svg');
}

h1 {
  font-family: Georgia, serif; 
  font-size: 48px; 
  color: #006AFF; 
  font-style: regular;
  font-weight: 100;
  line-height: 55px;
  margin: 0;
}

p {
  font-size: 14px; /* updated property */
  line-height: 16px;
  margin-bottom: 0px;
  margin-top: 0px;
}

.firstline {
  margin-bottom: 4px;
  width: 100%;
}

input[type="text"] {
  border: none;
  background: none;
  font-family: Georgia, serif;
  font-style: regular;
  font-weight: 100;
  font-size: 18px;
  line-height: 20px;
  height: 20px;
  width: 220px;
  margin-bottom: 0px;
  outline: none;

}

input[type="text"]::placeholder {
  color: #BFBAB0;
  line-height: 20px;
  height: 20px;

}

hr {
  border: none;
  border-top: 1px solid #E4DFD7;
  width: 100%;
  margin-top: 16px;
  margin-bottom: 24px;
}

.submit-button {
  width: 100%;
  height: 40px;
  background-color: #4A473F;
  border-radius: 6px;
  border: none;
  color: #fff;
  font-family: Georgia, serif;
  font-size: 18px;
  line-height: 22px;
  margin-top: 24px;
  transition: background-color 300ms;
  margin-top: 12px;
}

.submit-button:hover {
  background-color: #1A1814;
  transition: background-color 300ms;
  cursor: pointer;
}

.submit-button:active {
  background-color: #4A473F;
  transition: background-color 150ms;
  cursor: pointer;
}

.share-button {
  width: 100%;
  height: 40px;
  background-color: #006AFF;
  border-radius: 6px;
  border: none;
  color: #fff;
  font-family: Georgia, serif;
  font-size: 18px;
  line-height: 22px;
  margin-top: 24px;
  transition: background-color 300ms;
  margin-top: 12px;
}

.share-button:hover {
  background-color: #0054DB;
  transition: background-color 300ms;
  cursor: pointer;
}

.share-button:active {
  background-color: #006AFF;
  transition: background-color 150ms;
  cursor: pointer;
}


.firstline {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 2px;
  height: 20px;
}

.firstline p {
  margin: 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  color: #4A473F;
  margin-left: 8px;
  width: 100%;
  height: 20px;
}

.icon-right {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 20px;
  height: 20px;
}

.icon-right img {
  width: 20px;
  height: 20px;
}

.icon-left {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 20px;
  height: 20px;
}
.icon-left img {
  width: 20px;
  height: 20px;
}

input[type="text"]:focus::placeholder {
  opacity: 0;
}

@keyframes clown-fading {
  0% {
    opacity: 1;
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: scale(5);
  }
}

.clown {
  position: fixed;
  font-size: 50px;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
}

@keyframes crazyAnimation {
  0% {
    transform: rotate(0deg) scale(1);
    margin-top: 0;
  }
  25% {
    transform: rotate(90deg) scale(4.5);
    margin-top: 50px;
  }
  50% {
    transform: rotate(180deg) scale(2);
    margin-top: 100px;
  }
  75% {
    transform: rotate(270deg) scale(2.5);
    margin-top: 50px;
  }
  100% {
    transform: rotate(360deg) scale(1);
    margin-top: 0;
  }
}

.crazyBox {
  width: 100px;
  height: 100px;
  position: absolute;
  animation: crazyAnimation 5s infinite;
}